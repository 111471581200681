<template>
<div style="margin-top: 80px">
    <BasePageBreadCrumb :pages="pages" title="chamados" :menu="menu"/>
    <div class="col-12 p-0 d-none d-md-block">
        <div class="card-group my-3">
            <div class="card border-right">
                <div class="card-body">
                    <div class="d-flex d-lg-flex d-md-block align-items-center">
                        <div>
                            <div class="d-inline-flex align-items-center">
                                <h2 class="text-dark mb-1 font-weight-medium">{{ news }}</h2>
                            </div>
                            <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">novos</h6>
                        </div>
                        <div class="ml-auto mt-md-3 mt-lg-0">
                            <span class="opacity-7 text-muted"><i class="far fa-list-alt font-25"></i></span>
                        </div>
                    </div>
                </div>
            </div> 
            <div class="card border-right">
                <div class="card-body">
                    <div class="d-flex d-lg-flex d-md-block align-items-center">
                        <div>
                            <h2 class="text-dark mb-1 w-100 text-truncate font-weight-medium">{{ pending }}</h2>
                            <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">em andamentos</h6>
                        </div>
                        <div class="ml-auto mt-md-3 mt-lg-0 pr-1">
                            <span class="opacity-7 text-muted"><i class="far fa-hourglass font-25"></i></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card border-right">
                <div class="card-body">
                    <div class="d-flex d-lg-flex d-md-block align-items-center">
                        <div>
                            <div class="d-inline-flex align-items-center">
                                <h2 class="text-dark mb-1 font-weight-medium">{{ finished }}</h2>
                                <!-- <span class="badge bg-primary font-12 text-white font-weight-medium badge-pill ml-2 d-lg-block d-none mb-2">+18.33%</span> -->
                            </div>
                            <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">resolvidos</h6>
                        </div>
                        <div class="ml-auto mt-md-3 mt-lg-0">
                            <span class="opacity-7 text-muted"><i class="far fa-thumbs-up font-25"></i></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="d-flex d-lg-flex d-md-block align-items-center">
                        <div>
                            <h2 class="text-dark mb-1 font-weight-medium">{{ news + pending + finished }}</h2>
                            <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">todos</h6>
                        </div>
                        <div class="ml-auto mt-md-3 mt-lg-0">
                            <span class="opacity-7 text-muted"><i class="icon-globe font-29"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-8">
                            <div class="form-group d-flex">
                                <input type="search" class="form-control campo-procurar" placeholder="Procurar" v-model="search">
                                <button type="submit" class="btn btn-info" @click="fetchReports">Buscar</button>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 d-none d-lg-block">
                            <div class="form-group d-flex">
                                 <b-link :to="{name: 'ReportNew'}" class=" ml-auto">
                                    <button type="submit" class="btn btn-dark"><i class="far fa-file-alt mr-2"></i>Novo chamado</button>
                                 </b-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-11 col-lg-9 pb-2">
                    <div class="col-12">
                        <div class="row">
                           <div class="col-6 col-md-auto col-lg-auto pr-1">
                                <select class="custom-select mr-sm-2 campo-filter-1" v-model="filter.open_like" @change="fetchReports">
                                    <option :value="null">Tipo</option>
                                    <option value="LIQUIDATOR">Síndico</option>
                                    <option value="CLIENT">Cliente</option>
                                </select>
                            </div>
                            <div class="col-6 col-md-auto col-lg-auto pr-1 pl-1">
                                <select class="custom-select mr-sm-2 campo-filter-2" v-model="filter.status" @change="fetchReports">
                                    <option :value="null">Status</option>
                                    <option value="PENDING">Novo</option>
                                    <option value="IN_PROGRESS">Em andamento</option>
                                    <option value="FINISHED">Resolvido</option>
                                </select>
                            </div>
                            <div class="col-6 col-md-auto col-lg-auto pr-1 pl-1">
                                <b-form-select class="custom-select mr-sm-2 campo-filter-3" v-model="filter.technical_id" :options="technical" @change="fetchReports"></b-form-select>
                            </div>
                            <div class="col-6 col-md-auto col-lg-auto pr-1 pl-1">
                                <b-form-select class="custom-select mr-sm-2 campo-filter-4" v-model="filter.precedent_id" :options="precedents" @change="fetchReports"></b-form-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card-list-legenda"> 
                        <div class="card-body-list-legend d-none d-lg-block"> 
                            <div class="col-12 p-0 align-self-center">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12 col-md-12 col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status">
                                            <p class="text-truncate title-legend-list"><i class="fa fa-circle color-ico-legenda font-12" data-toggle="tooltip" data-placement="top" title="Status"></i></p>
                                        </div>
                                        <div class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                            <p class="text-truncate title-legend-list" style="width:45px">&nbsp;</p>
                                        </div>
                                        <div class="col-7 col-md-6 col-lg-9 p-0 order-3 align-self-center">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 col-md-12 col-lg-3 align-self-center pad-content-list">
                                                        <p class="text-truncate title-legend-list">Imóvel</p>
                                                    </div>
                                                    <div class="col-12 col-md-12 col-lg-2 align-self-center text-muted pad-content-list">
                                                        <p class="text-truncate title-legend-list">Nome</p>
                                                    </div>
                                                    <div class="col-auto col-md-auto col-lg-1 align-self-center pad-content-list">
                                                        <p class="text-truncate title-legend-list">N˚ torre</p>
                                                    </div>
                                                    <div class="col-8 col-md-4 col-lg-2 align-self-center text-muted pad-content-list">
                                                        <p class="text-truncate title-legend-list">N˚ apto</p>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4 align-self-center pad-content-list">
                                                        <p class="text-truncate title-legend-list">Assunto</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-list" v-for="item in reports" :key="item.id"> 
                        <div class="card-body-list"> 
                            <div class="col-12 p-0 align-self-center">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12 col-md-auto col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status d-none d-lg-block">
                                            <i class="fa fa-circle text-success font-12" data-toggle="tooltip" data-placement="top" title="Status"></i>
                                        </div>
                                        <div class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                            <img v-if="item.client.image" :src="item.client.image" alt="user" class="rounded-circle card-body-list-img" />
                                        </div>
                                        <div class="col-7 col-md-7 col-lg-9 p-0 order-3 align-self-center">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 col-md-12 col-lg-3 align-self-center pad-content-list">
                                                        <h6 class="text-truncate cod"><span>CÓD. TICKET:</span> {{ item.id }}</h6>
                                                        <h6 class="text-truncate card-body-list-title d-none d-lg-block">{{ item.enterprise.name }}</h6>
                                                        <div class="progress barra-progress">
                                                            <div class="progress-bar bg-primary" role="progressbar" style="width: 30%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-12 col-lg-2 align-self-center pad-content-list">
                                                        <h6 class="text-truncate card-body-list-title">{{ item.client.first_name}} {{ item.client.last_name }}</h6>
                                                        <h6 class="text-truncate cpf d-none d-lg-block">{{ item.client.cpf }}</h6>
                                                    </div>
                                                    <div class="col-auto col-md-auto col-lg-1 align-self-center pad-content-list">
                                                        <h6 class="text-truncate torre">{{ item.tower }}</h6>
                                                    </div>
                                                    <div class="col-8 col-md-4 col-lg-2 align-self-center pad-content-list">
                                                        <h6 class="text-truncate apto">{{ getFloors(item.floors) }}</h6>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4 align-self-center text-muted pad-content-list">
                                                        <h6 class="text-truncate empreendi">{{ item.subject }}</h6>
                                                        <h6 class="text-truncate cod-tec">Téc.: <span v-if="item.technical">{{ item.technical.first_name }} {{ item.technical.last_name }}</span></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">
                                            <b-link :to="{name: 'ReportEdit', params: {id: item.id}}" class="btn btn-success rounded-circle btn-circle font-20 card-body-list-cta" href="?app=assistenciatecnica/edit-chamado.php">+</b-link>
                                            <a class="btn btn-dark rounded-circle btn-circle font-20 ml-2" data-bs-toggle="modal" data-bs-target="#right-modal" href="javascript:void(0)" @click="id = item.id"><i class="fas fa-eye"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                 <BasePagination
                        v-if="reports.length > 0"
                        :totalPages="pagination.totalPages"
                        :activePage="pagination.currentPage"
                        @to-page="toPage"
                        @per-page="perPage" />
            </div>
        </div>
    </div>
<ViewReport :id="id" />

</div>
</template>
<script>
import ReportService from "@/services/resources/ReportService";
import TechnicalService from "@/services/resources/TechnicalService";
import ViewReport from '@/components/Reports/ViewReport';
import ConfigService from "@/services/resources/ConfigService";

const service = ReportService.build();
const serviceTechnical = TechnicalService.build();
const serviceConfig = ConfigService.build();

export default {
  data() {
    return {
        reports: [],
        news: 0,
        pending: 0,
        finished: 0,
        pagination: {
            totalPages: 1,
            currentPage: 1,
            perPage: 10,
            itens: 0
        },
        filter:{
            open_like: null,
            status: null,
            technical_id: null,
            precedent_id: null
        },
        precedents: [
            { value: null, text: 'Selecione' }
        ],
        technical: [
            { value: null, text: 'Selecione' }
        ],
        search: null,
        id: 0,
        pages:[
            {
                name: 'dashboard',
                to: 'Dashboard'
            },
            {
                name: 'assistẽncia técnica',
                to: 'Dashboard'
            },
            {
                name: 'chamados',
                to: 'Report'
            }
        ],
        menu:[
            {
                name: 'Novo chamado',
                to: 'ReportNew'
            },
            {
                name: 'Chamados',
                to: 'Report'
            },
            {
                name: 'Novo técnico',
                to: 'TechnicalNew'
            },
            {
                name: 'Técnicos',
                to: 'Report'
            },
            {
                name: 'Novo síndico',
                to: 'LiquidatorNew'
            },
            {
                name: 'Síndicos',
                to: 'Liquidator'
            }
        ]
    };
  },
  components:{
      ViewReport
  },
  methods: {
    getFloors(floors){
        return floors.map(elem => {
            return elem.unity.number;
        }).join(',');
    },
    toPage(page) {
        this.pagination.currentPage = page;
        this.fetchReports();
    },
    perPage(qtd) {
        this.pagination.currentPage = 1;
        this.pagination.perPage = qtd;

        this.fetchReports();
    },
    async fetchTechnical(){
        return serviceTechnical.search().then();
    },
    async fetchConfig(type){
        let data = {
        type: type
        }
        return serviceConfig.search(data).then();
    },
    fetchReports(){

        let data = {
            page: this.pagination.currentPage,
            per_page: this.pagination.perPage
        }

        if(this.filter.open_like){
            data.open_like = this.filter.open_like;
        }

        if(this.filter.status){
            data.status = this.filter.status;
        }

        if(this.filter.technical_id){
            data.technical_id = this.filter.technical_id;
        }

        if(this.filter.precedent_id){
            data.precedent_id = this.filter.precedent_id;
        }

        if(this.search){
            data.search = this.search;
        }
    

        if(this.search){
            data.search = this.search;
        }
      
        service
        .search(data)
          .then(resp => {
            this.reports = resp.data;
            this.pagination.totalPages = resp.last_page;
            this.pagination.itens = resp.total;
            this.getStatistics(resp.data);
          })
          .catch(err => {
            console.log(err)
          })
    },
    getStatistics(data){

        for(let i = 0; i < data.length; i++){
            if(data[i].status == 'PENDING'){
                this.news++;
            }else if(data[i].status == 'IN_PROGRESS'){
                this.pending++;
            }else if(data[i].finished == 'FINISHED'){
                this.finished++;
            }
        }

    }

  },
  async mounted() {
    this.fetchReports()

    var resp = await this.fetchTechnical();
    this.technical = this.technical.concat(resp.map(elem => {
        return {
            value: elem.id,
            text: elem.first_name + ' ' + elem.last_name
        }
    }))

    var resp = await this.fetchConfig('PRECEDENTS');
    this.precedents = this.precedents.concat(resp.map(elem => {
        return {
            value: elem.id,
            text: elem.name
        }
    }))

  },
};
</script>
<style scoped lang="scss">
.bg{
  background: #fff;
  padding: 10px;
  border: 1px #ddd solid;
  border-radius: 5px;
  
  .item{
    padding: 10px 0;
    border-bottom: 1px #ccc solid;
  }
}
</style>